.container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  height: 100vh;
}

.loadingMessage {
  color: #2545EB;
  font-family: "PublicSans";
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.19;
  letter-spacing: normal;
}

.loginButton {
  text-align: center;
  padding-top: 10px;
  height: 50px;
  border-radius: 28px;
  border-color:#fff;
  border: 1px solid rgba(7, 1, 247, 0.5);
  -webkit-background-clip: padding-box; /* for Safari */
  background-clip: padding-box;
  background-color: #2545EB;
  color: #fff;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  position: relative;
  margin-top: 20px;  
  margin-bottom: 4%;
}
