.mainDiv{
    height: 100vh;
    width: 100vw;
    /* background-color: yellow; */
}

.imgDiv{
    height: 70%;
    /* background-color: lightseagreen; */
}

.contentDiv{
    height: 30%;
    /* background-color: teal; */
    margin-right: 20%;
    margin-left: 20%;
    padding-top: 2%;
    padding-bottom: 2%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.cardBtn{
    width: 123px;
    height: 55px;
    color: #2545EB;
    text-align: center;
    border-radius: 33px;
    border: 1px solid #2545EB;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-family: PublicSans;
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
}

.btnArrow{
    height: 10px;
    width: 8px;
}

.imgDiv img{
    width: 100vw;
    height: 100%;
    object-fit: cover;
    content: url('../../assets/images/welcome_screen-img.png');
}

.leftDiv{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-start;
    font-family: PublicSans;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px; 
}

@media screen and (max-width:1440px) {
    .contentDiv{
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media screen and (max-width:1024px) {
    .contentDiv{
        margin-left: 12%;
        margin-right: 12%;
        padding-top: 4%;
        padding-bottom: 4%;
    }
}
@media screen and (max-width:767px) {
    .contentDiv{
        margin-left: 8%;
        margin-right: 8%;
        padding-top: 10%;
        padding-bottom: 10%;
    }
    .imgDiv img{
        width: auto;
    }
}
@media screen and (max-width:500px) {
    
    .contentDiv{
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
    }
    .leftDiv{
        width: 100%;
        height: 50%;
        margin-bottom: 20px;
        justify-content: space-around;
    }
}
