.footerDiv{
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: static;
    bottom: 0px;
    font-family: 'HelveticaNeue';
    font-weight: 300;
    margin-top: 10px;
    padding-bottom: 5px;
    padding-right: 30px;
    padding-left: 30px;
    width: 100%;
    
}

.condDiv{
    display: flex;
    flex-direction: row;
}

.footerDiv a{
    text-decoration: none;
    color: #2545EB;
}

.divider{
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #2545EB;
}
@media screen and (max-width:768px) {
    .footerDiv{
        height: 30px;
        font-size: 12px;
        position: static;
    }
}

@media screen and (max-width:426px) {
    .footerDiv{
        position: static;
    }
}
@media screen and (max-width:1480px) {
    .footerDiv{
        margin-top: 20px;
    }
}