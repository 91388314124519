.mainContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: center;
    background: radial-gradient(48.61% 77.73% at 26.94% 0%, #CCEFE8 0%, #FFF1E7 100%);
}

.subContainer{
    /* height: 50%; */
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-bottom: 0px;
}
.fig2{
    position: fixed;
    left: 67%;
    bottom: 50%;
}

.line1{
    position: fixed;
    right: 60%;
    top: 15%;
    height: 100px;
}

.fig1{
    position: fixed;
    right: 67%;
    top: 3%;
}

.line2{
    position: fixed;
    left: 60%;
    bottom: 67%;
    height: 100px;
}

.subText{
    display: flex;
    flex-direction: column;
    width: 30%;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #00000080;
    margin-top: 40px;
}

.docDiv{
    background-color: #A0EBDA50;
    border-radius: 11px;
    mix-blend-mode: multiply;
    height: 104px;
    width: 100%;
    padding: 10px;
    display: flex;
    flex-direction: row;
    
}

.docImg{
    height: 100%;
    border-radius: 10px;
}

p{
    margin: 0px;
}

.detail{
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    justify-content: space-between;
}

.dateContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 10px;
}

.date{
    height: 50px;
    width: 190px;
    background-color: #A0EBDA50;
    mix-blend-mode: multiply;
    border-radius: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
}

.note{
    background-color: #A0EBDA50;
    mix-blend-mode: multiply;
    border-radius: 11px;
    height: 134px;
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 15px;
    font-family: 'Helveticaneue';
    font-weight: 400;
    font-size: 10px;
}

.para{
    background: #FFFFFF;
mix-blend-mode: normal;
opacity: 0.4;
border-radius: 10px;
height: 150px;
margin-top: 5px;
padding: 5px;
}

.input{
    margin-top:10px !important;
    height: 80px !important;
}
.p1{
    font-family: 'PublicSans';
    font-weight: 400;
    font-size: 25px;
    line-height: 64px;
}

.docDiv1{
    display: flex;
    flex-direction: column;
}

.docP1{
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
}
.docP3{
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
}

.figures{
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
}

.figures p{
    font-size: 50px;
    line-height: 35px;
    font-family: 'PublicSans';
    font-weight: 400;
    color: #147C65;text-align: center;
}
.cardBtn{
    width: 103px;
    height: 55px;
    color: #2545EB;
    text-align: center;
    border-radius: 33px;
    border: 1px solid #2545EB;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    font-size: 16px;
    font-family: 'PublicSans';
    font-weight: bold;
}

@media screen and  (max-width:1445px) {
    .subContainer{
        width: 30%;
    }
    .fig1{
        right:68%;
        top: 10%;
    }
    .line1{
        right: 60%;
        top: 20%;
    }
    .line2{
        bottom: 63%;
    }
    .fig2{
        left: 70%;
    }
    .subText{
        /* width: 30%; */
    }
}
/* @media screen and  (max-width:1445px) {
    .mainContainer{
        height: 100%;
    }
    .fig1{
        top: 12%;
        right: 67%;
    }
    .line1{
        top: 22%;
    }
    .line2{
        top: 25%;
    }
} */
@media screen and  (max-width:1025px) {
    .subContainer{
        width: 45%;
        /* height: 70%; */
        margin-bottom: 20px;
    }
    .fig1{
        top:11%;
        right:70%;
    }
    .line1{
        top:22%;
        right: 65%;
        width: 100px;
    }
    .line2{
        left: 65%;
    width: 112px;
    top: 27%;
    }
    .fig2{
        left: 75%;
    }
    .subText{
        /* width: 73%; */
    }
    .cardBtn{
        margin-top: 20px;
    }
    .docDiv{
        height: 90px;
    }
    .note{
        /* height: 90px; */
    }
    .date{
        height: 45px;
    }
}

@media screen and  (max-width:769px) {
    .subContainer{
        width: 50%;
        /* height: 100%; */
        margin-bottom: 20px;
    }
    .fig1{
        top: 12%;
        right: 79%;
    }
    .p1{
        font-size: 20px;
    }
    .line1{
        top: 23%;
        right: 70%;
    }
    .line2{
        left: 69%;
        top: 27%;
    }
    .fig2{
        bottom: 50%;
        left: 80%;
    }
    .subText{
        width: 80%;
    }
    .cardBtn{
    }
    .docDiv{
    }
    .note{
    }
    .date{
    }
}

@media screen and  (max-width:500px) {
    .subContainer{
        width: 100%;
        /* height: 100%; */
        margin-bottom: 20px;
    }
    .mainContainer{
        height: auto;
    }
    .fig1{
        display: none;
    }
    .p1{
        font-size: 20px;
    }
    .line1{
       display: none;
    }
    .line2{
        display: none;
    }
    .fig2{
        position: static;
        margin-bottom: 30px;
    }
    .subText{
        width: 80%;
        font-size: 18px;
        line-height: 20px;
    }
    .cardBtn{
        margin-top: 20px;
    }
    .docDiv{
        height: 160px;
        padding: 25px;
    }
    .note{
        height: 160px;
    }
    .date{
        height: 61px;
        width: 156px;
    }
}