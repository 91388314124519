.mainContainer{
    height: 65%;
    padding-left: 10%;
    background-color:transparent;
    padding-right: 10%;
}

button:focus { outline: none !important;}

.container{
    height: 100%;
    padding-top: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color:transparent;
}

.midContainer{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: transparent;
}
.coverDiv{
    height: 100%;
}
.bookApp{

    
}
.proceedButton{
    width: 103px;
    height: 66px;
    border: 1px solid #2545EB;
border-radius: 33px;
color: #2545EB;
background-color: transparent;
outline: none;
}

.buttonDiv{
    margin-right: 2%;
    height: 60px;
    margin-top: 10px;
    margin-left: 0%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 0px;
    align-items: center;
    width: 100%;
    background-color:transparent;
    margin: 0px;
    font-family: 'PublicSans';
    font-weight: 700;
    font-size: 14px;
}
.cardBtn{
    width: 103px;
    height: 55px;
    color: #2545EB;
    text-align: center;
    border-radius: 33px;
    border: 1px solid #2545EB;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
}
.cardBtn2{
    width: 215px;
    height: 55px;
    color: #2545EB;
    text-align: center;
    border-radius: 33px;
    border: 1px solid #2545EB;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    outline: none;
}
.back{
    margin: 0px;
    font-family: 'PublicSans';
    font-weight: 700;
    font-size: 16px;
    margin-left: 5px;
}
.next{
    margin: 0px;
    font-family: 'PublicSans';
    font-weight: 700;
    font-size: 16px;
    margin-right: 7px;
}
.btnArrow{
    height: 10px;
    width: 8px;
}
.almost{
    position: relative;
    left: 17%;
    font-family: HelveticaNeue;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #2545EB;
    margin-right: 10px;
}
.cardBtn1{
    height: 46px;
    width: 103px;
    color: #2545EB;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
    outline: none;
}

.nav{
    display: flex;
    flex-direction: row;
    padding: 40px;
    background-color: transparent;
    justify-content: space-between;
    align-items: center;
}

.navBtn{
    height: 44px;
    width: 114px;
    color: #2545EB;
    background-color: transparent;
    border: 1px solid #2545EB;
    border-radius: 33px;
}

.modalStyles{
    background-color:yellowgreen;
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 25px;
    position: absolute;
    width: fit-content;
    z-index: 20;
    height: fit-content;
    inset: 40px;
    border-radius: 25px;
    border:none;
    margin: auto;
}

.modalOverlay{
    position: fixed;
    inset: 0px;
    background-color: rgba(0,0,0,0.5);
}

.p1{
    color: #1F6360;
    font-family: PublicSans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    margin-bottom: 10px;
}

.p2{
    font-family: "HelveticaNeue";
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #5C5C5C;
    margin-bottom: 32px;
}

.modalButton{
    width: 180.25px;
    height: 44px;
    background: #1F6360;
    border-radius: 33px;
    border: none;
    color:white;
}

.modalCloseDiv{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding-right: 3rem;
    padding-top: 2rem;
}

.modalContainer{
    display: flex;
    flex-direction: column;
    /* background-color: red; */
    justify-content: center;
    align-items: center;
    padding: 0rem 2rem;
    padding-bottom: 4rem;
    margin-inline: 10vw;
}


@media screen and (max-width:1441px) {
   .almost{
       left: 7%;
   }
}

@media screen and (max-width:1200px) {
   /*  .midContainer{
        width: 65%;
    } */
    .mainContainer{
        padding-right: 2%;
        padding-left: 2%;
    }
    .container{
        height: 100%;
    }
    .almost{
        left: 5%;
    }
}

@media screen and (max-width:1023px) {
   .mainContainer{
       padding-right: 1%;
       padding-left: 1%;
   }
   .almost{
    left: 0%;
}
.nav{
    padding-left: 1%;
    padding-right: 1%;
}
.modalContainer{
    /* margin-inline:20vw;
    padding: 0rem 1rem;
    padding-bottom: 3rem; */
    padding-bottom: 3rem;
}
.modalCloseDiv{
    padding-top: 1rem;
}
   
}
@media screen and  (max-width:1200px) {
    

}
@media screen and  (max-width:1200px) {
    .nav{
        padding-left: 20px;
        padding-right: 20px;
    }
    
}
@media screen and  (max-width:767px) {
  .container{
      flex-direction: column;
  }  
  .mainContainer{
      padding-left: 20px;
      padding-right: 20px;
      margin-top: 40px;
  }
  .midContainer{
      width: 100%;
      height: inherit;
      margin-top: 40px;
  }
  .buttonDiv{
      margin-top: 30px;
  }
  .modalContainer{
      padding-bottom: 3rem;
  }
}

@media screen and  (max-width:480px) {
    
    .mainContainer{}
    .almost{
        left: 2%;
    }
    .modalContainer{
        margin-inline: 0vw;
        padding-bottom: 3rem;
    }
    .modalCloseDiv{
        padding-right: 1rem;
    }
}

