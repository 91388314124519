.container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* justify-content: center; */
  /* background-image: url("./assets/images/background-lines.svg");
    background-size: cover; */
  /* height: 100vh; */
  /* width : 100%; */
  /* border: 1px solid red; */
}
body,
html {
  margin: 0;
  padding: 0;
  height: 100%;

  /* z-index: 100; */
  position: relative;
}

.bold {
  font-weight: bold;
}
