.mainContainer{
    background-color: transparent;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}
.p1{
    font-family: PublicSans;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 28px;
color: #000000;
margin-bottom: 30px;
}

.otpSection input{
    width: 48px !important;
    height: 48px !important;
    border-radius: 10px !important;
    outline: none !important;
    box-shadow: none !important;
    border: 0.5px solid #000000 !important;
}

.resend{
    display: flex;
    flex-direction:column;
    font-family: Helvetica Neue;
font-style: italic;
font-weight: normal;
font-size: 10px;
line-height: 122%;
margin-top: 5px;

}

.resendSub{
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
}


.verified{
    display: flex;
    flex-direction: row;
    position: relative;
    right: 0px;
}

.verifyBox{
    display: flex;
    background-color: transparent;
    flex-direction: column;
    align-items: center;
    height: 48px;
    justify-content: center;
    width: 87px;
    border: 1px solid #2545EB;
    border-radius: 33px;
    margin-left: 10px;
}

.ssnNo{
    width: 364px !important;
    height: 48px !important;
    border: 0.5px solid #000000 !important;
    box-sizing: border-box !important;
    border-radius: 10px !important;
    outline: none;
    font-size: 14px;
    padding-left: 20px;
    letter-spacing: 1px;
    font-family: 'HelveticaNeue';
    font-weight: 300;
}


.verify input{
width: 364px !important;
height: 48px !important;
border-radius: 10px !important;
outline: none !important;
box-shadow: none !important;
    border: 0.5px solid #000000 !important;
}

textarea{
    width: 364px;
    border: 0.5px solid #000000;
    box-sizing: border-box;
    border-radius: 10px;
    margin-top: 30px;
    outline: none;
    padding: 15px 20px;
    letter-spacing: 1px;
    font-family: 'HelveticaNeue';
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
}

.verify{
    display: flex;
    flex-direction: row;
    align-items: center;
}

form{

    width: 100%;
    display: flex;
    flex-direction: column;
}

.otpInputStyle{
    width: 48px !important;
    height: 48px !important;
    margin-top:0px !important;
    color: black;
    padding: 0px;
}

.phoneSection{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.otpSection{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.code{
    background: #FFAE91;
box-shadow: 0px 9px 30px rgba(255, 174, 145, 0.3);
border-radius: 8px;
}

::placeholder{
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    /* padding: 15px 20px; */
    font-family: 'HelveticaNeue';
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    opacity: 0.4;
    font-style: italic;
}

.wait{
    font-family: 'PublicSans';
    font-weight: bold;
    font-size: 12px;
}

.verifiedTag{
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    border: 1px solid #147C65;
    border-radius: 33px;
    height: 48px;
    padding: 15px;
}

.verified p{
    margin: 0px;
    text-align: left;
    font-size: 14px;
    font-family: 'PublicSans';
    font-weight: 700;
    color: #2545EB;
}

.resendOtp{
    color: #2545EB;
    font-family: 'PublicSans';
    font-weight: bold;
    font-size: 12px;
}

.verifiedTag p{
    margin: 0px;
    text-align: left;
    font-size: 14px;
    font-family: 'PublicSans';
    font-weight: 700;
    color:green;
    cursor: pointer;
}

.info{
    margin: 0px;
    text-align: left;
    font-size: 10px;
    font-style: italic;
    font-family: 'HelveticaNeue';
    font-weight: 400;
}
.react-tel-input {
    background-color: aqua;
}

@media screen and  (max-width:1200px) {}
@media screen and  (max-width:1024px) {
    .react-tel-input {
        width: auto !important;
        background-color: yellow;
    }
    .otpSection input{
        height: 40px !important;
        width: 40px !important;
    }
    .verify input{
        width: 320px !important;
    }
    .ssnNo{
        width: 320px !important;
    }
    textarea{
        width: 320px !important;
    }
    .phoneSection{
        flex-direction: column;
    }
    .verified{
    }
    .verify{
        margin-bottom: 10px;
        width: fit-content;
    }
    
}
@media screen and  (max-width:767px) {
    .react-tel-input {
        width: 100%;
    }
    input{
        width:100% !important;
    }
    textarea{
        width:81% !important;
    }
    .verify input{
        width: 100% !important;
    }
    .verify{
        margin-bottom: 10px;
        width: 100%;
    }
    .ssnNo{
        width: 81% !important;
    }
    .phoneSection{
        flex-direction: column;
    }
    .verified{
    }
    
}
@media screen and  (max-width:500px) {
    .verify input{
        width: 62% !important;
    }
    .verified{
        position:absolute;
        margin-right: 10px;
    }
    .ssnNo{
        width: 100% !important;
    }
    textarea{
        width:100% !important;
    }

}