.headDiv{
    height: 17%;
    display: flex;
    flex-direction: row;
    background-color:transparent;
    align-content: space-between;
    justify-content: space-between;
}
.headSubDiv{
    display: flex;
    flex-direction: row;
    background-color:transparent;
    text-align: left;
    margin-left: 0px;
    align-items: flex-end;
}

.subImg{
    height: 80px;
    width: 92px;
}
.addDocExtra p{
    min-width: 36px;
    margin: 0px;
    font-size: 8px;
    font-family: 'HelveticaNeue';
    font-style: italic;
    margin-right: 10px;
}

.docs{
    background-color:transparent;
    width: 30%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
}
.nav{
    display: flex;
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-right: 50px;
    padding-left: 50px;
    background-color: #FFF1E7;
    justify-content: space-between;
    align-items: center;
}

.navBtn{
    height: 44px;
    width: 114px;
    color: #2545EB;
    background-color: transparent;
    border: 1px solid #2545EB;
    border-radius: 33px;
}
.serviceDiv{
    background-color:transparent;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 20px;
}


hr{
    width: 98%;
    border: 0.5px solid #FF875C;
    margin-top: 25px;
    margin-bottom: 25px;
}

.addDoc{
    display: flex;
    flex-direction: row;
}

.addDocExtra{
    display: flex;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    align-items: inherit;
}

.serviceHead{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-end;
    text-align: left;
    background-color:transparent;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    width: 28%;
}

.docExtraImage1{
    height: 45px;
    width: 45px;
    margin:0px;
    border-radius: 10px;
    border: 2px solid #EAF5F5;
    /* z-index: 1; */
    position: relative;
    left: -15px;
}

.p1{
    font-size: 30px;
    line-height: 32px;
    margin: 0px;
    margin-bottom: 10px;
}
.p2{
    font-size: 16px;
    line-height: 18px;
    margin: 0px;
}

@media screen and (max-width:1441px) {
    .serviceHead{
        width: 33%;
    }
}

@media screen and (max-width:1200px) {
    .headSubDiv{
    }
    .serviceHead{
        width: 35%;
    }
}

@media screen and (max-width:1023px) {
    .headDiv{
        width: 100%;
        height: 15%;
    }
    .serviceHead{
        width: 40%;
    }
    .subImg{
        height: 70px;
        width: 80px;
    }
    .p1{
        font-size: 28px;
        line-height: 28px;
    }
    .p2{
        font-size: 14px;
        line-height: 16px;
    }
    
}
@media screen and  (max-width:768px) {
    .serviceHead{
        width: 50%;
    }
}

@media screen and  (max-width:767px) {
    .headDiv{
        height: 13%;
    }
    .serviceDiv{
        flex-direction: column;
        margin-left: 10px;
        justify-content: end;
    }
    .addDocExtra{
        align-items: flex-start;
    }
    .docs{
        width: 100%;
        align-items: flex-start;
    }
    .serviceHead{
        width: 100%;
    }
    .p1{
        font-size: 25px;
    }
    .p2{
        display: none;
    }
    .subImg{
        width: 103px;
        height: 87px;
    }
    .headSubDiv{
        align-items: flex-end;
    }
    .docExtraImage1{
      height: 31px;
      width: 31px;  
    }
    .addDocExtra p{
        font-size: 11px !important;
    }
    hr{
        margin-top: 25px;
        margin-bottom: 30px;
    }
}


