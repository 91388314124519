.container{
    height: 100%;
    overflow: hidden;
}
.container::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.midDiv{
    height: inherit;
    display: flex;
    flex-direction: row;
    overflow-wrap: break-word;
    flex-wrap: wrap;
    padding-top: 5px;
}

.time{
    height: 45px;
    width: 68px;
    border-radius: 10px;
    margin-left: 0px;
    margin-right: 15px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    border:0.5px solid #000000;
    cursor: pointer;
}

.noSlot{
    margin-left: 10px;
    font-size: 12px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    font-style: italic;
}

.timeRow{
    height: 100%;
    display: flex;
    margin-top: 5px;
    flex-direction: column;
    justify-items: flex-start;
    align-items: flex-start;
}

.timeDead{
    font-family: HelveticaNeue;
    font-size: 16px;
    font-weight:500;
    margin-top: 7px;
    margin-bottom: 7px;
}

@media screen and (max-width:480px) {
    .midDiv{
        overflow-wrap:break-word;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .time{
       
    }
   
}


@media screen and (max-width:767px) {
    
    .step2{
        height: 30%;
    }
    hr{
        margin-top: 20px;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:1023px) {
    
    hr{
        margin-top: 20px;
        margin-bottom: 20px;
    }

}