.mainDiv{
    background: rgba(20, 20, 20, 0.63);
    width: 100vw;
    height: inherit;
    position: fixed;
    z-index: 10;
    display: flex;
    flex-direction: column;
}
.nav{
    display: flex;
    flex-direction: row;
    margin:10px 1% 15px 35px;
    justify-content: flex-end;
}

.notificationDiv{
    display: flex;
    flex-direction: row;
    background-color: transparent;
    width: 100%;
    align-items: flex-start;
}

.cardDiv{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 30%;
}

.tutorialNotification{
    content: url('../../../assets/images/notification_text.svg');
}


.cardText1{
    left: 15%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.cardText2{
    left: 55%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    position: relative;
}

.cardEnclosingDiv{

}

@media screen and (min-width:1440px) {
    .nav{
        margin-right: 3%;
    }
    .tutorialNotification{
    }
    
}
@media screen and (max-width:1440px) {
    .nav{
        margin-right: 33px;
    }
    .tutorialNotification{
    }
    
}

@media screen and (max-width:1200px) {
    .tutorialNotification{
    }
    .docDiv{
        width: 55%;
    }
    .cardText2{
        left: 55%;
    }
}


@media screen and (max-width:1024px) {
    .nav{
        margin-right: 3px;
    }
    .tutorialNotification{
    }
    .docDiv{
        width: 50%;
    }
    .cardEnclosingDiv{
        width: 32%;
    }
    
}
@media screen and (max-width:900px) {
    .tutorialNotification{
    }
    .docDiv{
        width: 50%;
    }
}

@media screen and (max-width:767px) {
    .tutorialNotification{
        content: url('../../../assets/images/notification_responsive.svg');
    }
    .cardText2{
        content: url('../../../assets/images/car_text2_mobile.svg');
    }
    .cardDiv{
        width:100%;
    }
    .notificationDiv{
        flex-direction: column;
    }
    .cardText2{
        left: 25%;
    }
    .cardText1{
        position: relative;
        left: 25%;
    }
}
@media screen and (max-width:650px) {
    
    .tutorialNotification{
    }
    .cardText2{
        left: 5%;
    }
}
@media screen and (max-width:425px) {
    .tutorialNotification{
    }
    .cardText2{
        left: 5%;
    }
    .cardText1{
        left: 5%;
    }
    .containDiv{
        top: 20% !important;
    }
    .subCardDiv1{
        top: 20% !important;
    }
}







.headerDiv{
    padding-top: 0px;
    margin-top: 0px;
    height: 185px;
    padding-right: 14.5%;
    padding-left: 14.5%;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: 15%;
    
}

.mainDiv{
    display: flex;
    flex-direction: column;
    
}



.imageDiv{
    height: 100%;
    width: 45%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 5px;
}

.containDiv{
    display: flex;
    height: 100%;
    width: 60%;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding:0px;
}


.btnArrow{
    width: 8px;
    height: 8px;
}

.docDiv{
    width: 55%;
    height: 85%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: transparent;
    border-radius: 20px;
    border: 2ps solid red;
}

.subDocDiv{
    margin-top: 10px;
    padding:10px;
    height: 56px;
    width: 100%;
    mix-blend-mode:normal;
    background-color:white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-right: 20%;
}

.subDocDivSecond{
    margin-top: 10px;
    padding:10px;
    height: 56px;
    width: 100%;
    mix-blend-mode:normal;
    background-color:white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-right: 20%;
}

.docExtraImage{
    width: 14px;
    height: 14px;
}

.callImg{
    width: 9px;
    height: 9px;
}

.appDetail{
    font-size: 12px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    margin: 0px;
}

.docExtraImage1{
    height: 36px;
    width: 36px;
    border-radius: 8px;
}

.time p{
    margin-left: 5px;
}

.appDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 80%;
    width: 80%;
    text-align: left;
    margin-left: 10px;
}

.time{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.callIcon{
    width: 42px;
    height: 42px;
    mix-blend-mode: multiply;
    background-color: #FFF1E7;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}

.doctor{}

@media screen and (max-width:1440px) {
    .containDiv{
        width: 67%;
    }
}

 @media screen and (max-width:1200px) {
    .headerDiv{
        padding-left: 5%;
        padding-right: 5%;
    }
    .imageDiv{
        width: 50%;
    }
}

 
@media screen and (max-width:1023px) {
    .headerDiv{
        padding-left: 1%;
        padding-right: 1%;
        height: 200px;

    }
    
    .nav{
        padding-left: 20px;
        padding-right: 20px;
    }
    .docDiv{
        height: 82%;
        margin-bottom: 0px;
    }
    .appDiv{
        margin-left: 5px;
    }
    .appDetail{
        font-size: 12px;
    }
    .docExtraImage1{
        height: 30px;
        width: 30px;
    }
    .containDiv{
        width: 68%;
    }
    .imageDiv{
        width: 45%;
        margin-right: 10px;
    }
    .subDocDiv{
    }
    
    .time{
        font-size: 11px;
    }
    .callIcon{
        height: 32px;
        width: 32px;
    }
}

@media screen and (max-width:900px){
    .containDiv{
        width: 95%;
    }
}

@media screen and (max-width:767px) {
    .subDocDivSecond{
        display: none;
    }
    .headerDiv{
        flex-direction: column;
        height: 75%;
        padding-left: 5% ;
        padding-right: 5%;
    }
    .containDiv{
       width: 100%;
       height: 100%; 
       margin-top: 30px;
       position: relative;
       top: 43%;
    }

    .imageDiv{
        width: 100%;
        height: 55%;
        margin-top: 20px;
    }

    .doctor{
        width: 46%;
    }
    .subDocDiv{
        height: 60px;
    }

    .docExtraImage1{
        width: 36px;
        height: 36px;
    }
    
    .callIcon{
        height: 42px;
        width: 42px;
    }
    .appDiv{
        width: 81%;
        margin-left: 10px;
    }
    .doctor{
        width: 42%;
    }
}

@media screen and (max-width:650px) {
    .containDiv{
        top: 40%;
    }
}

.mainDiv2{
    display: flex;
    flex-direction: column;
}

.subCardDiv1{
    display: flex;
    flex-direction: column;
    margin-left: 12%;
    margin-right: 12%;
    align-content: flex-start;
    justify-content: flex-start;
    z-index: 15;
    position: relative;
    top: -20%;
    left: 33%;
}

.cardBtn p{
    margin: 0px;
    line-height: 8px;
}

.describe{
    display: flex;
    flex-direction: column;
    height: 50px;
    font-family: HelveticaNeue;
}

.cardSub{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
}

.cards{
    width: 30%;
    margin-left: 4%;
    margin-right: 7.5px;
    padding: 15px;
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: flex-start;
    font-family: 'HelveticaNeue';  
    font-weight: 400;
}
.p1{
    font-size: 20px;
    line-height: 20px;
      color: #147c65;
      margin: 0px;
      display: flex;
      font-family: 'HelveticaNeue';
       font-weight:400;
        text-align:left;
        margin-bottom: 4px;
}
.p2{
    font-size: 10px;
    line-height: 10px;
      margin: 0px;
      display: flex;
       font-family:HelveticaNeue;
       font-weight:300;
        text-align:left;
        margin-bottom: 0px;

}
.illustration{
    height: 50px;
    width: 60px;
}
.cardSubDiv1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
}

.btnArrow{
    width: 8px;
    height: 8px;
    margin-left: 4px;
}

.cardBtn{
    width: 70px;
    height: 34px;
    color: #2545EB;
    text-align: center;
    border-radius: 33px;
    border: 1px solid #2545EB;
    font-family: PublicSans;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.docDiv1{
    display: flex;
    flex-direction: row;
    padding: 10px;
    height: 60px;
    background-color:white;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
}

@media screen and (max-width:1024px) {
    .subCardDiv1{
        margin-right: 5%;
        margin-left: 5%;
    }
    .cards{
        margin-left: 5px;
        margin-right: 5px;
    }
}

@media screen and (max-width:1200px) {
    .subCardDiv1{
        margin-right: 2%;
        margin-left: 2%;
        left: 13%;
    }
    
}

@media screen and (max-width:1023px) {
    .subCardDiv1{
        margin-right: 1%;
        margin-left: 1%;
        justify-content: space-between;
        top:-28%;
        left: 6%;
    }
    .cards{
        margin-left: 5px;
        margin-right: 5px;
        padding: 10px;
        /* height: 20%; */
        width:32%;
    }
    
    .cardBtn{
        height: 35px;
        width: 70px;
        font-size: 12px;
    }
    .docDiv{
        margin-top: 10px;
        height: 68px;
        justify-content: flex-start;
    }
    .docImage{
        margin-right: 10px;
    }

    .illustration{
        height: 50px;
        width: 50px;
    }
    .p1{
        font-size: 16px;
        margin-bottom: 4px;
    }
    .p2{
        font-size: 10px;
        line-height: 10px;
    }
    .describe{
        height: 40px;
    }
    .cardSubDiv1{
        margin-bottom: 10px;
    }
    
}

@media screen and  (max-width:767px) {
    .subCardDiv1{
        flex-direction: column;
        align-items: center;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: -5px;
        top: 42%;
        left: 0%;
    }
    .cards{
       width: 100%;
       padding: 15px;
       margin-bottom: 0px;
       margin-left: 15px;
       margin-right: 15px;
    }
    
    .cardBtn{
        height: 40px;
        width: 90px;
        font-size: 14px;
    }
    .btnArrow{
        height: 12px;
        width: 12px;
    }
    .docDiv{
        width: 100%;
        margin-top: 5px;
        height: 68px;
    }

    .illustration{
        height: 40px !important;
        width: 40px !important;
    }
    .p1{
        font-size: 20px;
        line-height: 10px;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .p2{
       display: none;
    }
    .describe{
        height: 40px;
    }
    
}

@media screen and  (max-width:650px) {
    .subCardDiv1{
        top: 35%;
    }
}


.docImage{
    height: 48px;
    width: 48px;
    margin:0px;
    border-radius: 10px;
    margin-right: 10px;
}

.extraDoc1{
    height: 25px;
    width: 25px;
    margin:0px;
    border-radius: 5px;
    border: 1.625px solid #EAF5F5;
}

.addDocExtra p{
    margin: 0px;
    font-size: 8px;
    font-family: 'HelveticaNeueIt';
    font-weight: 400;
    font-style: italic;
    margin-right: 5px;
}


.addDoc{
    display: flex;
    flex-direction: row;
    position: relative;
    left: 30%;
}

.addDocExtra{
    display: flex;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    align-items: flex-end;
}



.detailDoc{
    width: 100%;
    font-family: Helvetica Neue;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.p1{
    font-size: 14px;
    line-height: 98%;
    color: #147C65;
    margin: 0%;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    margin-bottom: 2px;
}

.detailDoc .p1{
    color: black;
}

.p2{
    font-size: 10px;
    line-height: 12px;
    color: black;
    margin-top: -0px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    margin-bottom: 0px;
}

@media screen and (max-width:768px) {
    .docImage{
        height: 32px;
        width: 32px;
    }
    .docExtraImage1{
        height: 100%;
    width: auto;
    }
    .p1{
        font-size: 12px;
    }
    .p2{
        font-size: 10px;
    }
    .detailDoc{
        margin-top: 0px;
        margin-left: -3px;
        margin-right: 7px;
    }
    .addDocExtra p{
        margin-left: -10px !important;
    }
}

@media screen and (max-width:426px) {
    
    .docImage{
        height: 48px;
        width: 48px;
    }
    .docExtraImage1{
        height: 27px;
        width: 27px;
    }
    .addDocExtra{
        align-items: flex-end;
    }
    .addDocExtra p{
        margin-left: -10px !important;
    }
    .containDiv{
        top: 35%;
    }
    .subCardDiv1{
        top: 30%;
    }
}