.mainDiv{
    display: flex;
    flex-direction: column;
    background-color:transparent;
}

.wrapperDiv {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    gap: 20px;
    margin: 15px 14.5%;
}

.subCardDiv1{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 31.8%;
}

.cardBtn p{
    margin: 0px;
    line-height: 8px;
}

.describe{
    display: flex;
    flex-direction: column;
    height: 60px;
    background-color: transparent;
    font-family: HelveticaNeue;
}

.cardSub{
    background-color:transparent;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-content: center;
}

.cards{
    padding: 15px;
    background-color: rgba(240,240,240,0.5);
    border-radius: 25px;
    font-family: 'HelveticaNeue';  
    font-weight: 400;
}
.p1{
    font-size: 20px;
    line-height: 20px;
      color: #147c65;
      margin: 0px;
      display: flex;
      font-family: 'HelveticaNeue';
       font-weight:400;
        text-align:left;
        margin-bottom: 4px;
}
.p2{
    font-size: 10px;
    line-height: 14px;
      margin: 0px;
      display: flex;
       font-family:HelveticaNeue;
       font-weight:300;
        text-align:left;
        margin-bottom: 0px;
        max-width: 75%;

}
.illustration{
    height: 50px;
    width: 60px;
}
.cardSubDiv1{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    margin-bottom: 10px;
    cursor: pointer;
}

.btnArrow{
    width: 8px;
    height: 8px;
    margin-left: 4px;
}

.cardBtn{
    width: 70px;
    height: 34px;
    color: #1F6360;
    text-align: center;
    border-radius: 33px;
    font-family: PublicSans;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #E5EEEB;
    border: 1ps solid #F3FFFE;
}

.docDiv{
    display: flex;
    flex-direction: row;
    padding: 10px;
    height: 60px;
    background-color:white;
    align-items: center;
    justify-content: space-between;
    border-radius: 15px;
    margin-top: 15px;
}



@media screen and (max-width:1200px) {
    .wrapperDiv{
        margin-inline: 5%;
    }
    
}

@media screen and (max-width:1024px) {
    .wrapperDiv{
        gap: 15px;
        margin-inline: 1%;
    }
}

@media screen and (max-width:1023px) {
    
    .cardBtn{
        height: 35px;
        width: 70px;
        font-size: 12px;
    }
    .docDiv{
        padding: 10px;
        margin-top: 15px;
        height: 68px;
        justify-content: flex-start;
    }
    .docImage{
        margin-right: 10px;
    }

    .illustration{
        height: 50px;
        width: 50px;
    }
    .p1{
        font-size: 16px;
        margin-bottom: 4px;
    }
    .p2{
        font-size: 10px;
        line-height: 10px;
    }
    .describe{
        height: 50px;
    }
    .cardSubDiv1{
        margin-bottom: 10px;
    }
    
}

@media screen and  (max-width:767px) {
    .subCardDiv1{
        width: 48%;
    }
    
    .cardBtn{
        height: 40px;
        width: 90px;
        font-size: 14px;
        position: relative;
        top: 15px;
    }
    .btnArrow{
        height: 12px;
        width: 12px;
    }
    .docDiv{
        padding: 10px;
        margin-top: 5px;
        height: 68px;
    }

    .illustration{
        height: 40px !important;
        width: 40px !important;
    }
    .p1{
        font-size: 20px;
        line-height: 10px;
        margin-bottom: 15px;
        margin-top: 10px;
    }
    .p2{
       display: none;
    }
    .describe{
        height: 40px;
    }
   
    .mainDiv{
        padding-top: 20px;
    }
    
}

@media screen and  (max-width:560px) {
    .subCardDiv1{
        width: 100%;
    }
    .wrapperDiv{
        margin-inline: 5%;
    }
}