.headerDiv{
    padding-top: 0px;
    margin-top: 0px;
    height: 215px;
    background-color: transparent;
    padding-right: 14.5%;
    padding-left: 14.5%;
    display: flex;
    flex-direction: row;
    align-content: flex-end;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: 15%;
    
}

.mainDiv{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    /* background: radial-gradient(16% 72.5% at 28% 0%, #A0EBDA 0%, #E7F2F0  100%); */
    background:radial-gradient(258.58% 700.05% at 161.04% 288.68%, #A0EBDA 0%, #E7F2F0 71.49%);
    
}

.nav{
    display: flex;
    flex-direction: row;
    padding:40px;
    background-color: transparent;
    justify-content: space-between;
    column-gap: 1em;
}

.navBtn{
    height: 44px;
    width: 114px;
    color: #2545EB;
    background-color: transparent;
    border: 1px solid #2545EB;
    border-radius: 33px;
}

.viewAll{
    display: flex;
    flex-direction: row;
    align-items: baseline;
}

.imageDiv{
    height: 100%;
    width: 45%;
    background-color: transparent;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 5px;
}

.notifyDiv{
    background-color: #FFFFFF;
    opacity: 0.5;
    height: 90%;
    width: 100%;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;
}
.cardBtn1{
    height: 46px;
    width: 103px;
    color: #2545EB;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
}

.containDiv{
    display: flex;
    height: 100%;
    width: 33%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding:0px;
}

.subHead1{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 50%;
    background-color: transparent;
    background-color: transparent;
    margin-top: 0px;
    padding-bottom: 00px;
    font-family: PublicSans;
    margin-left: 17px;
}

.subHead1Para{
    font-size: 30px;
    line-height: 33px;
    text-align: left;  
    font-family: 'PublicSans';
    font-weight: 300;
}

.viewAll a{
    text-decoration: none;
    font-size: 14px;
    margin-right: 5px;
    margin-top: 10px;
    font-family: 'PublicSans';
    font-weight: bold;
    color: #2545EB;
    line-height: 20px;
}

.notifyDiv p{
    color:#1F6360;
    text-align: left;
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-size: 12px;
}
.btnArrow{
    width: 8px;
    height: 8px;
}

.subHead2{
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: center;
    width: 80%;
    height: 50px;
    background-color: white;
    border-radius: 15px;

}

.subHead2Para{
    font-size: 16px;
    line-height: 16px;
}

.docDiv{
    width: 100%;
    height: 85%;
    margin-bottom: 20px;
    display: flex;
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background-color: transparent;
    border-radius: 20px;
}

.subDocDiv{
    margin-top: 10px;
    padding:10px;
    height: 56px;
    width: 100%;
    mix-blend-mode:normal;
    background-color: #ffffff50;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
    margin-right: 20%;
}

.docExtraImage{
    width: 14px;
    height: 14px;
}

.callImg{
    width: 9px;
    height: 9px;
}

.appDetail{
    font-size: 12px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    margin: 0px;
}

.docExtraImage1{
    height: 36px;
    width: 36px;
    border-radius: 8px;
}

.time p{
    margin-left: 5px;
}

.notifications{
    font-size: 18px;
    text-align: left;
    margin: 0px;
    margin-left: 0px;
    font-family: 'PublicSans';
    font-weight: 500;
}

.back{
    margin: 0px;
    font-family: 'PublicSans';
    font-weight: 700;
    font-size: 14px;
}

.docExtraImage2{
    height: 80%;
    width: 45px;
    margin:5px;
    border-radius: 8px;
}
.appDiv{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 80%;
    width: 70%;
    text-align: left;
    margin-left: 10px;
}

.time{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.callIcon{
    width: 42px;
    height: 42px;
    mix-blend-mode: multiply;
    background-color: #FFF1E7;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.callIcon1{
    width: 15%;
    height: 80%;
    mix-blend-mode: multiply;
    background-color: #FFF1E7;
    align-content: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.displayAll{
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    z-index: 10;
    position:absolute;
    top:0;
    right: 0;
    width: 30%;
    height: 100vh;
    background-color: white;
    border-radius: 25px 0px 0px 25px;
    transition: 1ms;
    animation:to-left-1 1s linear forwards;
    
}

.subDocDivAll{
    margin-top: 10px;
    padding: 10px;
    width: 95%;
    mix-blend-mode:normal;
    background-color:#FFF1E7;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px;
}

.blur{
    filter:blur(2px);
}

.plan {
    background: #A0EBDA70;
    border-radius: 33px;
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #1F6360;
    padding: 6px 15px;
    margin-left: auto;
    text-transform: uppercase;
    mix-blend-mode: #A0EBDA70;
}

.doctor{}

 @media screen and (max-width:1200px) {
    .headerDiv{
        padding-left: 5%;
        padding-right: 5%;
    }
    .docDiv{
        width: 100%;
    }
    .subHead1{
        width: 65%;
        padding-right: 100px;
    }
    .imageDiv{
        width: 50%;
    }
}

@media screen and (max-width:1200px){

} 
@media screen and (max-width:1023px) {
    .headerDiv{
        padding-left: 1%;
        padding-right: 1%;
        height: 200px;

    }
    
    .nav{
        padding-left: 20px;
        padding-right: 20px;
    }
    .docDiv{
        width: 100%;
        height: 82%;
        margin-bottom: 0px;
    }
    .appDiv{
        margin-left: 5px;
    }
    .subHead1{
        width: 100%;
        padding-right: 10px;
    }
    .appDetail{
        font-size: 12px;
    }
    .subHead1 p{
        font-size: 22px;
        line-height: 26px;
    }
    .subHead2{
        align-items: center;
        width: 78%;
        font-size: 22px;
        line-height: 26px;
    }
    .subHead2 p{
        font-size: 18px;
        line-height: 20px;
    }
    .docExtraImage1{
        height: 30px;
        width: 30px;
    }
    .containDiv{
        justify-content: space-evenly;
        width: 35%;
    }
    .imageDiv{
        width: 45%;
        margin-right: 10px;
    }
    .subDocDiv{
        height: 46px;
    }
    
    .time{
        font-size: 11px;
    }
    .callIcon{
        height: 32px;
        width: 32px;
    }
}

@media screen and (max-width:767px) {
    .headerDiv{
        flex-direction: column;
        height: 75%;
        padding-left: 5% ;
        padding-right: 5%;
    }
    .containDiv{
       width: 100%;
       height: 100%; 
       margin-top: 30px;
    }

    .imageDiv{
        width: 100%;
        height: 55%;
        margin-top: 20px;
    }

    .doctor{
        width: 46%;
    }
    .subHead1{
        width: 100%;
        padding-right: 10px;
    }
    .subDocDiv{
        height: 56px;
    }

    .mainDiv{
        /* background: radial-gradient(64.63% 130.23% at 50.53% -14.09%, #CCEFE8 0%, #FFF1E7 100%); */
    }
    .docExtraImage1{
        width: 36px;
        height: 36px;
    }
    .viewAll{
        margin-bottom: 20px;
    }
    .docDiv{  
        padding: 0px;
    }
    .callIcon{
        height: 42px;
        width: 42px;
    }
    .appDiv{
        margin-left: 10px;
    }
    .subHead1{
        padding-right: 0px;
    }
    .doctor{
        width: 42%;
    }
}
