.mainContainer{
    margin-top: 0%;
    margin-left: 0px;
    padding-left: 0px;
    padding-right: 20px;
    padding-top: 0px;
    margin-right: 0px;
    border-radius: 30px;
    background-color: transparent;
    width: 35%;
}
.stepsDiv{
    display: flex;
    flex-direction: row;
    background-color: transparent;
}

.try{
    margin-right: 50px;
    background-color:#F8F8F8;
    padding-left: 20px;
    padding-top: 30px;
    padding-bottom: 0px;
    padding-right: 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 30px;
}
.step{
    margin-right: 0px;
    padding-right: 0px;
    text-align: left;
    display: flex;
    flex-direction: column;
    background-color:transparent;
    width: 27%;
    font-family: 'HelveticaNeue';
    font-weight: 400;
}
.docImg{
    margin-top: 20px
}
.header{
    margin-bottom: 20px;
    line-height: 24px;
    font-size: 20px;
    color: black;
    text-align: left;
    background-color: transparent;
    font-family: PublicSans;
    font-weight: 400;
}

.mainRow{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.p1{
    font-size: 14px;
    margin: 0px
}
.p2{
    font-size: 12px;
    margin: 0px;
    font-style: italic;
}
@media screen and (max-width:1024px) {
    
}

@media screen and (max-width:1200px) {
    .mainContainer{
        width: 45%;
    }
    .try{
        margin-right: 30px;
    }
}

@media screen and (max-width:1023px) {
   
   .try{
       margin-right: 0px;
       padding-left: 10px;
       padding-right: 10px;
   }
   .step{
    width: 30%;
}
    
}

@media screen and  (max-width:767px) {
    .docImg{
        display: none;
    }
    .mainContainer{
        width: 100%;
        padding: 0px;
    }
    .try{
        padding: 15px 20px 15px 20px;
    }
    .p2{
        margin: 0px;
        font-size: 12px;
    }
    .header{
        margin-bottom: 20px;
    }
    .step{
        width: 30%;
    }
    .mainRow{
        width: 100%;
    }
}

