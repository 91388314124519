.mainContainer{
    padding: 0px;
    display: flex;
    height: auto;
    flex-direction: column;
    margin-right: 0%;
    margin-top: 0%;
    margin-left: 0%;
    background-color:transparent;
    align-items: flex-start;
}
.step::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}
.step{
    width: 90%;
    background-color:transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items:flex-start;
    padding-left: 0%;
    padding-right: 3%;
    padding-top: 0%;
    padding-bottom: 0%;
    border-radius: 15px;
    margin-bottom: 0px;
}

.slotHeader{
    color:#2545EB;
    text-decoration: underline;
    font-weight: 400;
    font-family: PublicSans;
    cursor: pointer;
}

.step3{
    /* height: 26%; */
}
.step2{
    /* height: 32%; */
}

.expandPanel{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.expandedPanel{
    display: flex;
    flex-direction: column;
    background-color: transparent;
    height: 150px;
    width: 100%;
}

.header{
    height: 0px;
    margin: 0px;
    margin-left: 10px;
    font-family: PublicSans;
    font-size: 16px;
    font-weight: 700;
    color: black;
}

.subHead{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    margin-bottom: 15px;
}

.dateHeading{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
}

.hrLine{
    width: 100%;
    border: 0px;
    border-top: 2px solid #BFE0E0;
    margin-top: 25px;
    margin-bottom: 25px;
    opacity: 0.5;
    box-sizing: border-box;
}
.modalOverlay{
    position: fixed;
    inset: 0px;
    background-color: rgba(0,0,0,0.5);
}

.modalStyle{
    background-color:transparent;
    position: absolute;
    inset: 40px;
    z-index:20;
    width: fit-content;
    height:fit-content;
    min-height: 45vh;
    border-radius:25px;
    overflow:visible;
    padding:0px;
    border:none;
    margin: auto;
}

.modalContainer{
    background-color: white;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.modalpart1{
    display: flex;
    flex-direction: column;
}

.modalPart1Subpart1{
    width: 100%;
}
.modalPart1Subpart2{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 15px 30px 0px 0px;
}

input::placeholder {
    color: #000000;
    opacity: 0.3;
  }

.formDiv{
    display: flex;
    flex-direction: row;
}

.modalContent{
    padding: 2rem 8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.inputDiv{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-top: 15px;
}

.inputDiv input{
    width: 230px;
    /* width: 100%; */
    height: 55px;
    padding: 12px;
    border: 1px solid #BBBFC4;
}

.p1{
    font-family: PublicSans;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 30px;
    color: #1F6360;
    text-align: center;
}

.p2{
    font-family: HelveticaNeue;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 142%;
    text-align: center;
    color: #5C5C5C;
}

.formButton{
    background-color: #1F6360;
    color: white;
    font-size: 14px;
    text-align: center;
    width: 103px;
    height: 44px;
    border-radius: 33px;
    border: none;
    margin: 20px;
}

@media screen and (min-width:1440px) {  
}
@media screen and (max-width:1200px) {
}

@media screen and (max-width:1024px) {
    hr{
        margin-top: 20px;
        margin-bottom: 20px;
    }
    
}
@media screen and (max-width:767px) {
    .modalContent{
        padding: 2rem 4rem;
    }
    .step{
        width: 100%;
    }
    .step3{
        
    }
}

@media screen and (max-width:560px) {
    .modalContent{
        padding: 1rem 1rem;
    }
    .p1{
        font-size: 20px;
    }
    .p2{
        font-size: 12px;
    }
    .formDiv{
        flex-direction: column;
        align-items: flex-start;
    }
   .form{
       align-items: flex-start;
   }
   
}

