.mainContainer{
  height: 100vh;
  background-color: transparent;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.container{
  padding:6%;
  height: 100%;
  background-color:transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.subContainer1{
  height: 100%;
  width: 100%;
  background-color:transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.midContainer{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background-color: transparent;
  height: 100%;
  width: 70%;
}

.subContainer2{
  height: 100%;
  width: 100%;
  background-color:transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.welcomeText{
  font-family: 'PublicSans';
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
}

.panelText{
  font-family: 'PublicSans';
  font-weight: 400;
  font-size: 40px;
  line-height: 50px;
}

.welcomeDiv{
  width: 93%;
}

.logoImg{
  height: 43px;
  width: 242px;
}

.emailText{
  font-family: 'HelveticaNeue';
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  color: #147C65;
  margin-top: 20px;
  margin-bottom: 20px;
}


.arw{
  height: 15px;
  width: 15px;
  background-color: transparent;
}

.footer{
  height: 100px;
  width: 100%;
  display: flex;
  flex-direction: row;
  font-family: 'HelveticaNeue';
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #2545EB;
  align-items: flex-end;
  padding-bottom: 5px;
}
.vr{
  border-right: 3px solid #FFBDA5;
  width: 0px;
  height: 100%;
}

.divider{
  margin-left: 8px;
  padding-left: 8px;
  border-left: 1px solid #2545EB;
}

@media screen and (max-width:1441px) {
  .midContainer{
    width: 65%;
}
}
@media screen and (min-width:1441px) {
  .midContainer{
      width: 66.5%;
  }
}

@media screen and (max-width:1024px) {
  .midContainer{
      width: 85%;
  }
  .welcomeDiv{
      width: 100%;
  }
}
@media screen and (max-width:767px) {
  .container{
      flex-direction: column;
  }
  .vr{
      display: none;
  }
  .panelText{
      display: none;
  }
  .subContainer1{
      justify-content: center;
      height: auto;
  }
  .midContainer{
      width: 100%;
  }
  .mainContainer{
      padding-top: 20%;
  }
  .welcomeDiv{
      width: 98%;
  }
  input{
      width: 75% !important;
  }

  
  .midContainer{
    height: inherit;
  }
  .footer{
    height: 40px;
    /* position: absolute;
    bottom: 0px; */
  }
  .mainContainer{
    padding-top: 5%;
  }
}