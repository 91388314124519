.docImage{
    height: 48px;
    width: 48px;
    margin:0px;
    border-radius: 10px;
    margin-right: 10px;
}

.docExtraImage{
    height: 25px;
    width: 25px;
    margin:0px;
    border-radius: 5px;
    border: 1.625px solid #EAF5F5;
}

.addDocExtra p{
    margin: 0px;
    font-size: 8px;
    font-family: 'HelveticaNeue';
    font-style: italic;
    margin-right: 5px;
}

.docExtraImage1{
    height: 30px;
    width: 30px;
    margin:0px;
    border-radius: 5px;
    border: 2px solid #EAF5F5;
    z-index: 1;
    position: relative;
    left: -15px;
}

.addDoc{
    display: flex;
    flex-direction: row;
}

.addDocExtra{
    display: flex;
    margin-bottom: 0px;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    align-items: flex-end;
}



.detailDoc{
    width: 100%;
    font-family: Helvetica Neue;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}


.p1{
    font-size: 14px;
    line-height: 98%;
    color: black;
    margin: 0%;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    margin-bottom: 2px;
}

.p2{
    font-size: 10px;
    line-height: 12px;
    color: black;
    margin-top: -0px;
    font-family: 'HelveticaNeue';
    font-weight: 400;
    margin-bottom: 0px;
}

@media screen and (max-width:768px) {
    .docImage{
        height: 32px;
        width: 32px;
    }
    .docExtraImage1{
        height: 24px;
        width: 24px;
    }
    .p1{
        font-size: 12px;
    }
    .p2{
        font-size: 10px;
    }
    .detailDoc{
        margin-top: 0px;
        margin-left: -3px;
        margin-right: 7px;
    }
    .addDocExtra p{
        margin-left: -10px !important;
    }
}

@media screen and (max-width:426px) {
    
    .docImage{
        height: 48px;
        width: 48px;
    }
    .docExtraImage1{
        height: 27px;
        width: 27px;
    }
    .addDocExtra{
        align-items: flex-end;
    }
    .addDocExtra p{
        margin-left: -10px !important;
    }
}