@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/ButlerStencil-Medium.woff2") format("woff2"),
    url("../assets/fonts/ButlerStencil-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/ButlerStencil.woff2") format("woff2"),
    url("../assets/fonts/ButlerStencil.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/ButlerStencil-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/ButlerStencil-ExtraBold.woff") format("woff");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/ButlerStencil-Black.woff2") format("woff2"),
    url("../assets/fonts/ButlerStencil-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/ButlerStencil-UltraLight.woff2") format("woff2"),
    url("../assets/fonts/ButlerStencil-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/ButlerStencil-Bold.woff2") format("woff2"),
    url("../assets/fonts/ButlerStencil-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/ButlerStencil-Light.woff2") format("woff2"),
    url("../assets/fonts/ButlerStencil-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../assets/fonts/Butler/Butler_Medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../assets/fonts/Butler/Butler_Regular.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../assets/fonts/Butler/Butler_ExtraBold.otf") format("opentype");
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../assets/fonts/Butler/Butler_Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler";
  src: url("../assets/fonts/Butler/Butler_Black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/Butler/Butler_Ultra_Light.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/Butler/Butler_Bold.otf") format("opentype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Butler Stencil";
  src: url("../assets/fonts/Butler/Butler_Light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../assets/fonts/helveticaneue/HelveticaNeue-Light.ttf")
    format("truetype");

  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../assets/fonts/helveticaneue/HelveticaNeue-Light.ttf")
    format("truetype");

  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../assets/fonts/helveticaneue/HelveticaNeue-Light.ttf")
    format("truetype");

  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeue";
  src: url("../assets/fonts/helveticaneue/HelveticaNeuBold.ttf")
    format("truetype");

  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "HelveticaNeueIt";
  src: url("../assets/fonts/helveticaneue/HelveticaNeueIt.ttf")
    format("truetype");

  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "PublicSans";
  src: url("../assets/fonts/PublicSans-Thin.ttf") format("opentype");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "PublicSans";
  src: url("../assets/fonts/PublicSans-Regular.ttf") format("opentype");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "PublicSans";
  src: url("../assets/fonts/PublicSans-Medium.ttf") format("opentype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "PublicSans";
  src: url("../assets/fonts/PublicSans-Bold.ttf") format("opentype");
  font-weight: 700;
  font-style: normal;
}
