.emailButton {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 20px;
}

.emailSent {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  font-family: "HelveticaNeue";
  font-size: 16px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: start;
  color: #147C65;
}
.arw{
  height: 15px;
  width: 15px;
  background-color: transparent;
}

input{ 
  width: 80%;
  height: 56px;
  border: 0.5px solid #000000;
  box-sizing: border-box;
  border-radius: 11px;
  outline: none;
  padding-left: 15px;
  font-family: 'HelveticaNeue';
  font-weight: 500;
}

.emailDiv{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content:flex-start;
}

.btnDiv{
  margin-left: 20px;
  border-radius: 50px;
  height: 56px;
  width: 56px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #2545EB;
}

.warning{
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  color: #FF705C;
  align-items: flex-start;
  margin-top: 20px;
}

.warning img{
  margin-right: 10px;
  
}
.emailError{
  /* padding: 10px; */
}
.emailError p{
  color: #147C65;
}

.warningDiv{
  margin-top: 20px;
}

