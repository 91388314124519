.calendarDiv1{
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: space-between;
    background-color:transparent;
    flex-direction: row;
}

.containDiv{
    max-width: 486px;
    background-color: transparent;
}


.secondDiv1{
    margin-left: 40px;
    padding-bottom: 5px;
    margin-right: 20px;
    flex-direction: row;
    display: flex;
    overflow: scroll;
    scrollbar-width: none;
    
}


.subDiv1{
    font-family: 'HelveticaNeue';
    font-weight: 400;
    text-align: center;
    padding: 5px;
    width: 50px;
    height: 70px;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 10px;
    border-radius: 8px;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease;
}


.secondDiv1::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.smallDiv1{
    width: 45px;
    background-color:transparent;
    font-size: 12px;
    
}

.subDiv1:hover{
    border: 0.5px solid #000000;
box-sizing: border-box;
border-radius: 8px;
}

.calendarImage{
    width: 20px;
    margin-right: 5px;
}
.monthLabel{
    position: sticky;
    width: 95px;
    top: 0;
    left: 0;
    height: 100%;
    padding: 5px;
    border-radius: 10px;
    align-content: center;
    color:black;
    font-size: 12px;
    font-family: PublicSans;
    font-weight: 700;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    margin-left: 0px;
}

.monthDiv{
    display: flex;
    flex-direction: column;
}
.heading{
    font-family: Helvetica;
    font-size: 25px;
    color: #2545EB;
    font-weight: 300;
}
.monthLabelDiv{
    background-color: transparent ;
    margin-bottom: 0px;
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-left: 0px;
}

.arrow{
    height: 14px;
    margin-top: 50px;
    width: 20px;
}

.datesDiv{
    margin-top: 0px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.activeDate{
    background-color: transparent;
}
.activeDate1{
    background-color: transparent;
}

.buttonWrapper1 {
    position: absolute;
    display: flex;
    align-items: flex-end;
    /* z-index: 5; */
    background: inherit;
    cursor: pointer;
}

.buttonWrapper2 {
    display: flex;
    align-items: flex-end;
    /* z-index: 5; */
    background: inherit;
    cursor: pointer;
}

.button {
    text-decoration: none;
    cursor: pointer;
    font-size: 20px;
    font-weight: bold;
}


@media screen and (max-width:1024px) {
    .calendarDiv1{
        width: 100%;
    }
    .containDiv{
        width: 100%;
    }
}

@media screen and (max-width:769px) {
    .calendarDiv1{
        width: 100%;
    }
    .secondDiv1{
        margin-left: 20px;
        margin-right: 10px;
    }
    .containDiv{
        max-width: 396px;
    }
}


@media screen and (max-width:426px) {
    .calendarDiv1{
    }
    .secondDiv{
        
    }
    .secondDiv1{
        margin-right: 10px;
        margin-left: 30px;
    }
    .subDiv1{
        width: 50px;
        height: 80px;
    }
    .secondDiv1{
        
    }
    .containDiv{
        max-width: 286px;
    }
    .buttonWrapper1{
        margin-left: -10px;
        height: 100%;
        align-items: center;
    }
    .buttonWrapper2{
        height: 100%;
        align-items: center;
    }
}