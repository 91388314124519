.leftContainer {
    background:#EEF0FF;
    width:35%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.logoImg {
    height: 30px;
    width: 200px;
    margin-left: 70px;
}

.illustration {
    width: 100%;
}

.leftHeader {
    font-family: 'PublicSans';
    font-weight: 400;
    font-size: 36px;
    line-height: 42px;
    margin-left: 70px;
    white-space:pre-wrap;
    color: #64688B;
}

.footerLinks {
    display: flex;
    flex-direction: row;
    font-family: 'PublicSans';
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    align-items: flex-end;
    margin-left: 70px;
}

.footerLinks a{
    text-decoration: none;
}

.divider {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #000000;
}

@media screen and (max-width:1024px) {
    .leftContainer{
        width: 40%;
    }
    .logoImg{
        margin-left: 25px;
    }
    .leftHeader{
        margin-left: 20px;
    }
}

@media screen and (max-width:768px) {
    .leftContainer{
        display: none;
    }
}