.containDiv{
    display: flex;
    flex-direction: column;
    background-color: transparent;
}
.doctorDiv{
    display: flex;
    width: 100%;
    padding: 0px;
    align-items: center;
    justify-content: center;
    background-color:transparent;
    flex-direction: row;
}

.secondDoctorDiv{
    margin-left: 0px;
    margin-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    width: 100%;
    flex-direction: row;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    
}

.subDoctorDiv{
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    margin-right: 7px;
    border-radius: 8px;
}

.secondDoctorDiv::-webkit-scrollbar {
    -webkit-appearance: none;
    display: none;
}

.smallDoctorDiv{
    width: 45px;
    background-color: transparent;
}


.image{
    margin-right: 10px;
    height: 100%;
    border-radius: 5px;
}

.para{
    font-size: 12px;
    line-height: 15px;
    margin:0px;
}
.mainDiv1{
    height: 46px;
    width: 143px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background-color:teal;
    border: 0.5px solid #000000;
    border-radius: 8px;
    padding: 5px;
    font-family: 'HelveticaNeue';
    font-family: 400;
    cursor: pointer;
}

.docName{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.head1{
    font-size: 8px;
    font-weight: 300;
    margin: 0px;
}

@media screen and (max-width:1024px) {
    
}

@media screen and (max-width:1200px) {
    
}

@media screen and (max-width:1023px) {
   
    
}

@media screen and  (max-width:426px) {
    .secondDoctorDiv{
        flex-wrap: wrap;
        margin-right: 0px;
    }
    .mainDiv1{
        margin:0px 5px 15px 0px;
        width: 150px;
        height: 56px;
        padding: 10px;
    }

    
}


