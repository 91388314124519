.mainContainer{
    background-color: #FFFFFF;
    height: 245px;
    width: 350px;
    margin: auto;
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3%;
    z-index: 10;
    position: fixed;
    left: 0px;
    right: 0px;
    bottom: 0px;
    top: 0px;
}

.modalText{
    font-family: 'HelveticaNeue';
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: #147C65;
    margin-bottom: 40px;
}

.modalRow{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    background-color: transparent;
}

.auroraLogo{
    height: 20px;
}

.snapLogo{
    height: 35px;
    position: relative;
    top: 4px;
}

.arrowImg{
    height: 15px;
}
