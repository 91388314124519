.modalOverlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0,0,0,0.5);
    height: 100vh;
    overflow: scroll;
}
.modalOverlay::-webkit-scrollbar{
    display: none;
}
.modalStyles {
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 20px;
    position: absolute;
    z-index: 20;
    inset: 40px;
    border-width: 1px;
    border-style: solid;
    border-color: #4BA998;
    margin: auto;
    width: 60vw;
}


.modalHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 25px;
    background-color: #F4F4F4;
    border-radius: 20px 20px 0px 0px;
    padding: 32px 40px;
}

.header {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #147C65
}

.subHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    padding-top: 8px;
    white-space: pre-line;
}

.modalBody {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding-top: 50px;
    /* padding-bottom: 80px; */
    /* height: 80%; */
    border-radius: 0px 0px 20px 20px;
    padding-inline: 60px;
    background-color: white;
}

.bodyHeader {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    color: #113A39;
}

.stepper {
    display: flex;
    flex-direction: column;
    width: 33.1%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    white-space: pre-line;
}

.appScreen {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-align: center;
    color: #B3C0BD;
    margin-top: 45px;
    margin-bottom: 5px;
}

.downloadLink {
    font-family: 'Helvetica Neue';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #546362;
    margin-bottom: 54px;
    margin-top: 5px;
}

.imgMaxWidth {
    max-width: 100%;
    margin-bottom: 13px;
}

.modalBody a {
    text-decoration: underline;
    color: #546362;
    font-size: 16px;
    line-height: 24px;
}

.logo {
}

.imgPadLeft {
    padding-left: 20px;
}

@media screen and (max-width: 1300px) {
    .modalStyles{
        width: 70vw;
    }
}
@media screen and (max-width: 1100px) {
    .modalStyles{
        width: 80vw;
    }
}

@media screen and (max-width: 560px) {
    .modalStyles {
        width: 85vw;
        height: 90vh;
        inset: 0;
    }
    .modalBody {
        padding-inline: unset;
        padding-top: 20px;
        padding-bottom: 20px;
        flex-direction: column;
        overflow: scroll;
        justify-content: flex-start;
        gap: 30px;
    }
    .stepper {
        width: 100%;
    }
    .logo {
        display: none;
    }
    .header {
        text-align: center;
        font-size: 20px;
        line-height: 24px;
    }
    .subHeader {
        text-align: center;
        white-space: unset;
        padding-top: 0px;
    }
    .downloadLink {
        margin-bottom: 10px;
    }
    .appScreen {
        margin-top: 10px;
    }
}