@import "./styles/custom-font.css";

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  font-family: 'PublicSans' !important;
}

@media only screen and (max-width: 425px) {
  .container {
    padding: 0 20px;
  }
}

html {
  overflow: hidden;
  width: 100%;
}

body {
  height: 100%;
  position: fixed;
  /* prevent overscroll bounce*/

  overflow: hidden;
  -webkit-overflow-scrolling: touch;
}
/* iOS velocity scrolling */

.scrollableContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}
