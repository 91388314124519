.mainContainer {
    display: flex;
    flex-direction: row;
    height: 100vh;
    width: 100%;
}

.rightContainer {
    width:65%;
}

.outerContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 30%;
    margin-top: 15%;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    width: 300px;
}

.title {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 700;
    font-size: 38px;
    line-height: 42px;
    width: 50%;
}

.subtitle {
    margin-top: 20px;
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    color: #000000;
    line-height: 130.8%;
    width: 110%;
}

.email {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    color: #2545EB;
    line-height: 130.8%;
}

.stepper {
    margin-top: 15px;
}

.stepperActive {
   display: inline-block;
    border-bottom: 2px solid #3147ED;
    width: 60px;
    margin: 5px;
}

.stepperInactive {
    display: inline-block;
    border-bottom: 2px solid #CACACA;
    width: 60px;
    margin: 5px;
}

.label {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #464646;
    margin-top: 20px;
}

.input {
    box-sizing: border-box;
    border: 1px solid #8B8B8B;
    border-radius: 8px;
    height: 54px;
    width: 300px;
}

.phoneInputDiv input {
    box-sizing: border-box;
    border: 1px solid #8B8B8B !important;
    border-radius: 8px !important;
    height: 54px !important;
    width: 300px !important;
    outline: none !important;
    box-shadow: none !important;
    font-family: 'PublicSans';
}

.btn {
    height: 54px;
    background: #1F6360;
    border-radius: 8px;
    color: white;
    border: 0px;
    cursor: pointer;
    width: 300px;
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.disabledBtn {
    height: 54px;
    background: #1F6360;
    opacity: 0.58;
    border-radius: 8px;
    color: white;
    border: 0px;
    cursor: pointer;
    width: 300px;
    margin-top: 25px;
    pointer-events: none;
    font-family: 'PublicSans';
}

.contactLabel {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #9CA3AB;
    margin-top: 20px;
}

.contactLink {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #1F6360;
    text-align: center;
    cursor: pointer;
}

.otpLabel {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 36px;
    color: #464646;
}

.phoneNumber {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #2545EB;
}

.otpInputStyle {
    width: 60px !important;
    height: 75px !important;
    margin-top: 20px !important;
    color: black;
    padding: 20px !important;
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
}

.otpContainerStyle {
    justify-content: space-between;
    margin-top: 20px;
}

.verified {
    margin-top: 20px;
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #1F6360;
}

.modalOverlay {
    position: fixed;
    inset: 0px;
    background-color: rgba(0,0,0,0.5);
}

.modalStyles {
    background: #FFFFFF;
    mix-blend-mode: normal;
    border-radius: 8px;
    position: absolute;
    width: fit-content;
    z-index: 20;
    height: fit-content;
    inset: 40px;
    border:none;
    margin: auto;
}

.modTitle {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    text-align: center;
    letter-spacing: 1px;
    color: #000000;
    margin-top: 35px;
}

.modSubTitle {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 1px;
    color: #515C67;
    width: 70%;
    margin-top: 25px;
    margin-bottom: 15px;
}

.modalCloseDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding-right: 2rem;
    padding-top: 2rem;
}

.modalContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 500px;
}

.modalCloseImage {
    cursor: pointer;
}

.error {
    font-family: 'PublicSans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #D50000;
    width: 300px;
    text-align: left;
    margin-bottom: 0px;
}

.errorImg {
}

.errorDiv {
    padding: 12px;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: flex-start;
    box-sizing: border-box;
    background: #FFF3F2;
    border: 1px solid #FFBBBB;
    border-radius: 8px;
}

.logoImg {
    display: none;
    height: 43px;
    width: 200px;
    margin-bottom: 50px;
}

.footerLinks {
    margin-top: 50px;
    display: flex;
    flex-direction: row;
    font-family: 'PublicSans';
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    justify-content: center;
    display: none;
}

.divider {
    margin-left: 8px;
    padding-left: 8px;
    border-left: 1px solid #000000;
}

.inputDiv{
    display:  flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: 16px;
    border: 1px solid #8B8B8B;
    border-radius: 8px;
}

.inputDiv input{
    border: none;
    width: 100%;
}

.loader{
    width: 20%;
    height: 55px;
}

.buttonText{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-family: 'PublicSans';
}
.buttonTextLoading{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-right: 20%;
}

.loaderImg{
    width: 55px;
    height: 50px;
}

.eyeIcon{
    cursor: pointer;
}

.btnLogin {
    background: #1F6360;
    border-radius: 8px;
    color: white;
    border: 0px;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 20px;
}

@media screen and (max-width:768px) {
    .rightContainer{
        width: 100%;
    }
    .outerContainer{
        align-items: center;
        margin-top: 0%;
        margin-left: 0%;
    }
    .mainContainer{
        align-items: center;
    }
    .footerLinks{
        display: flex;
    }
    .logoImg{
        display: block;
    }
    .title{
        width: 100%;
        text-align: center;
    }
    .modalContainer {
        width: 400px;
    }
}

@media screen and (max-width:560px) {
    .modalContainer {
        width: 300px;
    }
    .outerContainer{
        align-items: flex-start;
        padding-inline: 20px;
    }
    .title{
        text-align: left;
    }
    .innerContainer{
        width: 100%;
    }
    .phoneInputDiv input{
        width: 100% !important;
    }
    .disabledBtn{
        width: 100%;
    }
    .btn{
        width: 100%;
    }
    .otpContainerStyle{
        justify-content: flex-start;
        gap: 20px;
    }
}
